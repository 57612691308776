import React, { useState, useEffect } from 'react';
import Card from './Card';

import fireDB_config from '../firebase';
import fireDB from '../firebase';
// import {ref} from 'firebase/database';

import Header from './Header';
import configData from "../config.json";

const Home = () => {

  // fetch detail on firebase
  const [data, setData] = useState({});

  useEffect(() => {
    
    fireDB
    .child(configData.DB_NOTES )
    
    // fireDB_config
    // .database()
    // .child(configData.DB_NOTES )
    // .ref( configData.DB_NOTES )
    
    .orderByChild("percent")
    .limitToLast(30)
    .on("value", (snapshot) => {
      
      if (snapshot.val() !== null) {
        setData({ ...snapshot.val() });
        
      } else {
        setData({});
      }
    });

    return () => {
      setData({});
    }
  }, []);


  return (
   <>
  <Header name="Live stocks" />

    <div className="container flex flex-wrap justify-center p-5 mt-0">
      {Object.keys(data).map((id) => (
        <>
          <Card noteData={data} id={id} class="h-96" />
        </>
      ))}
    </div>
      </>
  )
}

export default Home