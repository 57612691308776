import Home from './Home';
import { useState, useEffect } from 'react';

import { ToastContainer } from 'react-toastify';
import { Outlet } from 'react-router-dom';

// const h1_name = "Live Stocks";
const Main = () => {
    
    const [h1_name, setData] = useState("Live Stocks");
    return (
 <>
<ToastContainer
position="bottom-center"
autoClose={2000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss={false}
draggable
pauseOnHover
theme="colored"
/>


        <Outlet />
</>       

    )

}

export default Main;
