import React, { useState, useEffect, useRef } from 'react'
import { CardActionArea } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';
import Modal from '@mui/material/Modal';
import fireDB from '../firebase';
import configData from "../config.json";
import ImageIcon from './ImageIcon';

const Card = ({ noteData, id }) => {
  // modal open close
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // delete Note
  const DeleteNote = async (id) => {
    fireDB.child(configData.DB_NOTES + `/${id}`).remove((err) => {
      if (err) {
        toast.error(err)
      }
      else {
        toast.success("Note Deleted successfully")
      }
    })
  }

  //Editnote
  const obj = { title: noteData[id].title, notesdetail: noteData[id].notesdetail
    , currentDate : noteData[id].currentDate
    , img : noteData[id].img
    , price : noteData[id].price ? noteData[id].price : '' 
    , low : noteData[id].low ? noteData[id].low : '' 
    , high : noteData[id].high ? noteData[id].high : '' 
  }

  const [state, setState] = useState( obj )
  
  const mounted = useRef( )

  const sym = noteData[id].title

  const { title, notesdetail,currentDate, img , price, low } = state;
  const { prevLow , prevHigh , prevPrice } = '';

  const handleInput = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  }

  const updatePrice = (e) => {
    console.log("up Price")
  }

  
  useEffect( () => {
    if(! mounted.current){
      // console.log("first render", sym)
      mounted.current = true;
    } else {
    }
  }, [price, low ] )
  
  /*
  useEffect( () => {
    if(! mounted.current){
      console.log("first render", sym)
      mounted.current = true;
    } else {
      
    const current_low = noteData[id].low

    // console.log('changed', price , sym )
    // console.log('prev-low', prevLow , sym )
    
    if( prevLow ){
      if( prevLow != low ){
          console.log('new-low', prevLow , sym )
          debugger;
        }

      setState({ ...state, [prevLow]: current_low });
    } else {
      setState({ ...state, [prevLow]: current_low });
    }

    if( prevPrice ){
      if( prevPrice != price ){
          console.log('new-price', prevPrice , sym )
          debugger;
        }

      setState({ ...state, [prevPrice]: price });
    } else {
      setState({ ...state, [prevPrice]: price });
    }
    
    
    // console.log('low', current_low , sym )
    // console.log('prev-price', prevPrice , sym )

      
    }
      
    
  });
  */


  const handleChange_price = (e) => {
    // const { name, value } = e.target;
    console.log('hdn changed', e)
  }

  const EditNote = async (e) => {
    e.preventDefault();

    await fireDB.child(configData.DB_NOTES + `/${id}`).set(state, (err) => {
      if (err) {
        toast.error(err);
        handleClose();
      } else {
        toast.success("Save Successful")
        handleClose();
      }
    })
  }

  return (
    <>
      <div className="item_stock box w-full md:w-auto bg-slate-800 p-0 mb-3 md:m-5 lg:l-5 rounded-xl hover:bg-gradient-to-l from-pink-500 to-blue-500 break-inside-avoid-column"
        key={id}
        data-symbol={noteData[id].symbol ? noteData[id].symbol : noteData[id].title}
        data-price={noteData[id].price}
        >
        <CardActionArea>
          <div className="innertextsection h-100 md:w-80 rounded-xl bg-slate-900 p-2 overflow-y-auto">
            <h2 className='text-blue-500 font-semibold text-xl mb-1'>{noteData[id].title}
            <h6 className='text-white font-semibold data_price'>${noteData[id].price}</h6></h2>
          </div>
        </CardActionArea>
        <div className="edit-box flex justify-between items-center p-4">
            { noteData[id].img ? <ImageIcon img={noteData[id].img} /> : null }
          <div className="date">
            <h6 className='text-white font-semibold data_percent'>{noteData[id].percent}%</h6>
            <h6 className='text-white'>{noteData[id].sentiment}</h6>

            <div class="">
              Low: <b className='text-white range_day data_low '>{noteData[id].low}</b>
              <br />
              High: <b className='text-white range_day data_high'>{noteData[id].high}</b>
            </div>
            {/* <p className='text-white break-words text-sm'>{noteData[id].headline}</p> */}
          </div>

          {/* <div className="editbtn flex gap-2">
            <div className="btn bg-blue-300 p-2 rounded-full cursor-pointer hover:translate-y-1" onClick={handleOpen}>
              <EditIcon />
            </div>
          </div> */}

        </div>
      </div>

      
      {/* Editpage Modal*/}
      
      <div className='h-screen fixed z-10'>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="absolute flex justify-center items-center"
        >
          <div className="bg-slate-800 p-4 rounded-lg border-none">
            <form method='POST' className='addnoteform w-[290px]  md:w-[400px] flex flex-col gap-4'>
              <div className="imputbox">
                <input type="text"
                  placeholder='Title' name="title" className='w-full outline-none font-semibold p-3 rounded-lg'
                  value={state.title}
                  onChange={handleInput}
                />
              </div>
              <div className="imputbox">
                <textarea rows="4" type="text"
                  placeholder='write your note...'
                  name="notesdetail" className='resize-none font-semibold w-full outline-none rounded-lg p-3'
                  value={state.notesdetail}
                  onChange={handleInput}
                />
              </div>
              <div className="imputbox">
                <input type="text"
                  placeholder='Image' name="img" className='w-full outline-none font-semibold p-3 rounded-lg'
                  value={state.img}
                  onChange={handleInput}
                />
              </div>
              <div className="submitbtn text-center">
                <input type="submit" className='bg-green-400 font-semibold p-3 rounded-lg cursor-pointer hover:translate-y-1' value="Edit Note"
                  onClick={EditNote}
                />
              </div>
            </form>
          </div>
        </Modal>
      </div>


    </>
  )
}

export default Card;

