import React from 'react'

import Main from './components/Main';
import Home from './components/Home';

import Quote from './components/Quote';
import NoPage from './pages/NoPage';

import Addnote from './components/Addnote';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';


function App() {
  
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />} >

          <Route index element={<Home />} />
          <Route path="/q/:symbol" element={<Quote />} />
          <Route path="*" element={<NoPage />} />

        </Route>
      </Routes>

    </BrowserRouter>
  )
}

export default App
