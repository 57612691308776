import firebase from "firebase/compat/app";
import "firebase/compat/database";


  const firebaseConfig = {
    apiKey: "AIzaSyBB_hBQKcVCeBew9zEMxxfy_4TXrtuk6Y4",
    authDomain: "stocks-100.firebaseapp.com",
    databaseURL: "https://stocks-100-default-rtdb.firebaseio.com",
    projectId: "stocks-100",
    storageBucket: "stocks-100.appspot.com",
    messagingSenderId: "1032820423305",
    appId: "1:1032820423305:web:d016da9afa4a0ef2f52458",
    measurementId: "G-ZEGZSQKQC2"
  };
  
const fr_config = firebase.initializeApp(firebaseConfig);
export const fireDB_config = fr_config;
export default fr_config.database().ref();
// export default fireDB.database().ref();
// export const fireDB_ref = fireDB_db().ref;
// export default fireDB_db().ref();
// 
