import { useParams } from 'react-router-dom';

import Header from './Header';

const Quote = ( props ) =>{
    
    let { symbol } = useParams();

    // const h1_name = "Live Stocks";
    return <>
    
    <Header name={symbol} />
    
    <h1>Quote</h1>
    {symbol}
    </>
}
export default Quote;